.choice-result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

/* 타이틀 */
.choice-result-title {
    width: 100%;
    margin-bottom: 20px;
}

.choice-result-title > p {
    font-weight: 800;
}


/* 게이지 바 */
.choice-result-var {
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid lightgray;
}


/* Progress bar container */
.choice-result-progress-bar {
    width: 100%;
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 10px;
}

/* Fill element */
.choice-result-progress-bar-fill {
    height: 100%;
    background-color: #378ff5;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

/* count box */
.choice-result-header-count-box {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.choice-result-header-count-box p {
    font-weight: 700;
}

.choice-result-header-count-box > div {
    width: 140px;
    display: flex;
    align-items: center;
}

.choice-result-header-total-count {
    flex-direction: row-reverse;
}

.choice-result-header-count-box span {
    width: 40%;
    text-align: center;
    padding: 5px 0;
    border-radius: 20px;
    font-weight: 600;
}

.choice-result-header-count-box > .choice-result-header-count > span {
    background-color: #a9e9ff;
    margin-right: 10px;
}

.choice-result-header-count-box > .choice-result-header-total-count > span {
    background-color: #d9dde8;
    margin-left: 10px;
}


/* 정답 오답 박스 */

.choice-result-fail-content-box {
    margin-bottom: 30px;
}
.choice-result-ok-box,
.choice-result-fail-box {
    width: 100%;
}

.choice-result-ok-title-box,
.choice-result-fail-title-box {
    width: 100%;
    padding: 30px 0;
}

.choice-result-ok-title-box > p {
    font-size: 17px;
    font-weight: 700;
    color: rgb(0 157 1);
}

.choice-result-fail-title-box > p {
    font-size: 17px;
    font-weight: 700;
    color: rgb(244 73 73);
}

.choice-result-ok-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choice-result-ok-content > .choice-result-ok-map-content-box {
    width: 100%;
    border: 2px solid #edeff5;
    display: flex;

    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1), 0 0px 0px rgba(0, 0, 0, 0);
    color: #282e3e;
    font-size: 17px;
    font-weight: 500;
}

.choice-result-ok-map-content-box > .choice_result-ok-map-meaning {
    width: 30%;
}

.choice-result-ok-map-content-box > .choice_result-ok-map-content {
    width: 60%;
}

.choice-result-ok-map-content-box > .choice_result-ok-map-icon {
    display: flex;
}

.choice-result-ok-map-content-box > .choice_result-ok-map-icon > svg {
    margin-right: 10px;
    cursor: pointer;
}

.choice-change-position-var {
    background: #d7dbe6;
    height: 1.5rem;
    width: .0625rem;
}


.choice-result-not-data {
    width: 100%;
    border: 2px solid #edeff5;
    display: flex;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1), 0 0px 0px rgba(0, 0, 0, 0);
    color: #282e3e;
    font-size: 17px;
    font-weight: 500;
    padding: 20px 0 20px 23px;
}


/* 500 이하 */
@media (max-width: 500px) {
    .choice-result-all {
        width: 95%;
        margin-top: 20px;
    }

    .choice-result-title > p {
        font-size: 26px;
    }

    .choice-result-ok-title-box {
        padding: 20px 0;
    }

    .choice-result-ok-content > .choice-result-ok-map-content-box {
        flex-direction: column;
        padding: 5px;
        position: relative;
    }

    .choice-result-ok-map-content-box > div {
        padding: 5px 3px 5px 14px;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-icon {
        position: absolute;
        right: -6px;
        top: 8px;
    }

    .choice-change-position-var {
        display: none;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-meaning,
    .choice-result-ok-map-content-box > .choice_result-ok-map-content {
        width: 70%;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-icon {
        width: 25%;
    }
}


/* 500 이상 */
@media (min-width: 500px) {
    .choice-result-all {
        width: 90%;
        margin-top: 30px;
    }

    .choice-result-title > p {
        font-size: 30px;
    }

    .choice-result-ok-content > .choice-result-ok-map-content-box {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .choice-result-ok-map-content-box > div {
        padding: 15px 3px 15px 23px;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-icon {
        padding: 15px 3px 15px 0;
    }


}

/* 1000 이상 */
@media (min-width: 1000px) {
    .choice-result-all {
        width: 70%;
        margin-top: 40px;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-icon {
        width: 8%;
    }

    .choice-result-ok-map-content-box > .choice_result-ok-map-icon {
        padding: 15px 3px 15px 0;
    }


}