.check-password-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

}

.check-password-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.check-password-lock-box {
    width: 60px;
    height: 60px;
    border: 3px solid black;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
}

.check-password-text-box {
    font-size: 14px;
    margin-bottom: 20px;
}

.check-password-input-box > input {
    width: 240px;
    height: 45px;
    padding: 10px 15px;
    font-size: 16px;
    font-family: inherit;
    margin-bottom: 20px;
    border: 1px solid #777;
}

.check-password-button-box,
.close-password-button-box {
    width: 100%;
}

.check-password-button-box > button {
    border-style: none;
    border-radius: 15px;
    width: 95%;
    height: 50px;
    background-color: black;
    font-size: 19px;
    color: white;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}
.check-password-button-box > button:hover {
    background-color: #1b1b1b;
    color: white;
}
.close-password-button-box > button {
    border-style: none;
    border-radius: 15px;
    width: 95%;
    height: 50px;
    background-color: #f2f4f7;
    font-size: 19px;
    color: #667085;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.close-password-button-box > button:hover {
    background-color: #b4b4b4;
    color: white;
}

/* 버튼 박스 */
.check-password-btn-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}