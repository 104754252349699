.favorite-page-all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.favorite-page-mid .favorite-info {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    width: auto;
}

.favorite-page-mid .favorite-info > p {
    display: inline-block;
    border-bottom: 3px solid #2668f1;
}


/* 카테고리 박스 */
.favorite-page-category-box {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-bottom: 5px;
}

.favorite-page-study-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.favorite-page-study-box > div {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 6px 10px;
    color: #4a4a4a;
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.favorite-page-study-box > div:hover {
    background-color: rgba(40, 135, 244, 0.65);
    color: white;
    border: 1px solid #f1f1f1;
}

.favorite-data {
    display: flex;
    flex-direction: column;
}

.favorite-page-category-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.favorite-page-category-right > svg {
    cursor: pointer;
    color: gray;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.favorite-page-category-right > svg:hover {
    color: black;
}

/* 단어 박스 내용 */
.favorite-box {
    border: 1px solid #4e4f4f85;
    border-radius: 5px;
    padding: 15px 15px 10px 15px;
    margin-top: 15px;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);

}

.favorite-data-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.favorite-data-top-content {
    font-size: 22px;
    color: #2668f1;
}

.favorite-data-top-audio-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.favorite-data-middle {
    border: 1px solid #8282824a;
    border-radius: 3px;
    padding: 10px;
    background-color: #f1f1f14a;
    margin-bottom: 20px;
    font-weight: 500;
}

.favorite-data-memo {
    border: 1px solid #8282824a;
    border-radius: 3px;
    padding: 4px 10px 10px 10px;
    background-color: #2fb9f51f;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.favorite-data-memo span {
    font-size: 13px;
    color: #4a4a4a;
}

.favorite-data-memo > div {
    display: flex;
    justify-content: space-between;
}

.favorite-data-memo > div > div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.favorite-data-memo button {
    border: none;
    background-color: transparent;
    font-size: 13px;
    cursor: pointer;
}

.favorite-date-memo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.favorite-date-memo-title > p {
    font-size: 10px;
}

.favorite-data-memo > input {
    font-size: 15px;
    border: none;
    background-color: transparent;
    margin-top: 5px;
    outline: none;
}


.favorite-data-memo-input {
    border-bottom: 1px solid #777;
}

.favorite-data-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.favorite-data-bottom-creat-time > p {
    font-size: 13px;
    color: #595959;
}

.favorite-data-bottom-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.favorite-data-bottom-icon-box > svg {
    cursor: pointer;
}


.study-play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}


/* 600 이하 */
@media (max-width: 600px) {
    .favorite-page-mid {
        width: 95%;
        margin-top: 20px;
    }

    .favorite-data-top-content {
        font-weight: 500;
    }

}

/* 600 이상 */
@media (min-width: 600px) {
    .favorite-page-mid {
        width: 90%;
        margin-top: 30px;
    }

    .favorite-data-top-content {
        font-weight: 600;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .favorite-page-mid {
        width: 70%;
        margin-top: 40px;
    }

}