/* Translator.css */

.translator-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0;
    background-color: white;
}

.translator-box {
    border: 1px solid rgba(0, 0, 0, 20%);
    width: 650px;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 20%);
    background-color: white;
}

.translator-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 20%);
}

.translator-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.translator-icon-box > svg {
    width: 30px;
    height: 21px;
}

.translator-content-box {
    border-bottom: 1px solid rgba(0, 0, 0, 20%);
    padding: 20px 20px 63px;
    position: relative;
}

.translator-content-box > textarea {
    width: 100%;
    line-height: 1.26em;
    overflow: hidden;
    resize: none;
    border-color: transparent;
    outline: none;
}

.translator-select-box {
    width: 80%;
}

.translator-button-box {
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.translator-button-submit > button {
    border-radius: 0 0 12px 0;
    background-color: #A0D995;
    border: none;
    color: white;
    width: 100px;
    height: 50px;
    font-size: 17px;
    font-weight: 600;
}

.translator-button-other {
    display: flex;
}

.translator-button-other-audio-box,
.translator-button-other-copy-box {
    height: 50px;
    padding: 10px 15px;
    border-right: 1px solid rgba(0, 0, 0, 20%);
}

.translator-button-other-audio-box svg {
    width: 30px;
    height: 30px;
}

.translator-button-other-copy-box svg {
    width: 25px;
    height: 30px;
}

.active_border {
    border-color: #21dc6d;
}

/* react-select custom styles */
.custom-select__control {
    background-color: white !important;
    border-color: #ccc !important;
    border-radius: 4px !important;
    padding: 5px !important;
    box-shadow: none !important;

}

.custom-select__control:hover {
    border-color: #aaa !important;
}

.custom-select__option {
    background-color: #fff !important;
    color: #666 !important;
}

.custom-select__option--is-selected {
    background-color: #A0D995 !important;
    color: #333 !important;
}

.custom-select__option--is-focused {
    background-color: rgba(160, 217, 149, 0.65) !important;
}

.custom-select__menu {
    border-radius: 4px !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
}

.custom-select__single-value {
    color: #333 !important;
}

/* 600 이하 */
@media (max-width: 600px) {
    .translator-container {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

    .translator-box {
        width: 98%;
        margin-bottom: 10px;
    }

    .custom-select__control {
        width: 115px !important;
    }

    .custom-select__menu {
        width: 115px !important;
    }
    .translator-content-box {
        min-height: 200px;
    }
    .translator-content-box > textarea {
        font-size: 18px;
    }
    .translator-read-box > .translator-title-box {
        display : none;
    }
    .translator-write-box {
        width: auto;
    }
    .translator-select-box {
        display: block !important;
    }
}

/* 600 이상 */
@media (min-width: 600px) {
    .translator-box {
        width: 98%;
        margin: 10px;
    }

    .custom-select__control {
        width: 120px !important;
    }

    .custom-select__menu {
        width: 120px !important;
    }

    .translator-content-box {
        min-height: 300px;
    }
    .translator-content-box > textarea {
        font-size: 24px;
    }
}

/* 1000 이상 */
@media (min-width: 1000px) {
    .translator-box {
        width: 650px;
        margin: 10px;
    }
    .translator-content-box {
        min-height: 300px;
    }
    .translator-content-box > textarea {
        font-size: 24px;
    }

}
