.test-page-all {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: #f6f7fb;

}

.test-page-mid{
  
  background-color: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.0);
}

.test-off-box{
  display: flex;
  flex-direction:column;
  align-items: center;
  padding: 40px 0 35px 0;
}

.test-off-info > h5 {
  padding-bottom: 5px;
}

.test-off-info {
  margin-bottom: 20px;

}
.test-off-req-box > input{
  width: 50px;
  height: 23px;
  text-align: center;
  font-size: 13px;
  margin-right: 5px;
}
.test-off-req-box > button{
  width: 80px;
  height: 40px;
  background-color: #6CC4A1;
  border-style: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.kind-box {
  margin-bottom: 20px;
}

.kind-box > button {
  width: 70px;
  height: 35px;
  border-style: none;
  border-radius: 6px;
  margin: 0 5px;
}

.active{
  background-color: #A0D995;
  color: white;
}

/* 500 이하 */
@media(max-width : 600px){
  .test-page-mid{
    width: 350px;
  }
  .study-off-info {
    font-size: 15px;
  }
}

/* 500 이상 */
@media(min-width : 600px){
  .test-page-mid{
    width: 500px;
  }
  .test-off-info {
    font-size: 18px;
  }
}
 /* 1000 이상 */
@media(min-width : 1000px){
  .test-page-mid{
    width: 48vw;
  }
  .test-off-info {
    font-size: 20px;
  }
}