.study-page-all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.study-on-header-box {
    transition: transform 0.6s ease;
}

.study-on-header-box.fade-out {
    transform: rotateX(360deg);
}


.study-on-header-box {
    background-color: white;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.4), 0 0px 0px rgba(0, 0, 0, 0);
    border-radius: 10px;
    position: relative;
}

.on-header-box {
    padding: 13px 15px 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.on-header-left-box,
.on-header-right-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.on-header-left-box > svg {
    margin-right: 10px;
    cursor: pointer;
}


/* 단어 박스 */
.on-word-box {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-and-chinese {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-and-chinese > .content-and-chinese-content {
    font-size: 20px;
}

.content-block-box {
    width: 70px;
    height: 30px;
    background: #d1cfcf;
}

.chinese-block-box {
    width: 100px;
    background: #d1cfcf;
}

.on-click-box {
    display: flex;
    justify-content: center;
    padding: 0 15px 10px 15px;
    align-items: center;
    margin: 3px 0 20px 0;
}

.on-click-box p {
    font-size: 25px;
}

.click-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.click-mid p {
    margin: 0 20px;
    font-size: 18px;
}

.click-mid > svg,
.click-left > svg {
    cursor: pointer;
}

.study-on-play-box {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}


/* Progress bar container */
.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0;
}

/* Fill element */
.progress-bar-fill {
    height: 100%;
    background-color: #2ac4f8;
    border-radius: 5px 5px 0 0;
    transition: width 0.3s ease-in-out;
}


/* 이용 방법 버튼 박스 */
.study-rule-box {
    font-size: 16px;

}

.study-rule-box span {
    color: #006ae5;
    font-weight: 600;
}

.study-rule-box label {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.study-rule-box input[type="checkbox"] {
    appearance: none;
    position: relative;
    border: max(2px, 0.1em) solid gray;
    border-radius: 1.25em;
    width: 2.25em;
    height: 1.25em;
}

.study-rule-box input[type="checkbox"]::before {
    content: "";
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    transform: scale(0.8);
    background-color: gray;
    transition: left 250ms linear;
}

.study-rule-box input[type="checkbox"]:checked {
    background-color: #b2b9ea;
    border-color: #dbdfff;
}

.study-rule-box input[type="checkbox"]:checked::before {
    background-color: #4255ff;
    left: 1em;
}

.study-rule-box input[type="checkbox"]:disabled {
    border-color: lightgray;
    opacity: 0.7;
    cursor: not-allowed;
}

.study-rule-box input[type="checkbox"]:disabled:before {
    background-color: lightgray;
}

.study-rule-box input[type="checkbox"]:disabled + span {
    opacity: 0.7;
    cursor: not-allowed;
}

.study-rule-box input[type="checkbox"]:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) solid #4255ff;
}

.study-rule-box fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


/* 이용방법 내용 박스 */
.help-box {
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5fa5f6;
    border-radius: 0 0 10px 10px;
    color: white;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

/* 테스트, 학습 으로 가는 박스 */
.study-page-test-type-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 30px 0;
}

.study-page-test-type-box > div {
    background-color: #f6f7fb;
    border-radius: .5rem;
    width: 32%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
    transition: all .12s cubic-bezier(.47, 0, .745, .715);

}

.study-page-test-type-box > div:hover {
    background-color: #d0d0ff;
}

.study-page-test-type-box img {
    padding-top: 10px;
    height: 40px;
    margin-bottom: 7px;
}

.study-page-test-type-box p {
    font-size: 16px;
    font-weight: 600;
}

/* 레벨 박스 */
.study-level-change-box {
    width: 100%;
    background-color: #f6f7fb;
    border-radius: 10px;
    padding: 10px 20px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
}

.study-level-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
}

.study-level-box > div {
    background-color: white;
    border-radius: 5px;
    height: 45px;
    padding: 10px;
    margin-right: 10px;
    font-weight: 500;
    text-align: center;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
    cursor: pointer;
}

.study-level-box > div:hover {
    background-color: #d0d0ff;
}


/* 히라가나, 한자 박스 */
.study-change-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
}

.study-change-box > div {
    background-color: white;
    border-radius: 5px;
    width: 50%;
    height: 45px;
    padding: 10px;
    margin-left: 10px;
    font-weight: 500;
    text-align: center;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
    cursor: pointer;
}

.word-on {
    border: 1px solid #5d9cff;
}

.word-off {
    color: #d1cfcf;
}


/* 단어 전체 리스트 */
.study-page-word-list-box {
    width: 100%;
    position: relative;
}

.study-page-word-list-title-box {
    margin: 35px 0 15px 0;
    cursor: pointer;
}

.study-page-word-list-title-box > p {
    margin-right: 5px;
}

.study-page-word-list-title-box > svg {
    padding-top: 5px;

}

.study-page-word-list-title-box > p {
    font-size: 22px;
    font-weight: 600;
    display: inline-block;

}

.study-page-word-list-content-box {
    padding: 20px 20px 3px 20px;
    border: none;
    background-color: #f6f7fb;
    border-radius: 10px;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);

}

.study-page-word-list-content-box.show {
    max-height: 10000px;
    opacity: 1;
}

.study-page-word-list-content-box.hide {
    max-height: 0;
    opacity: 0;
}

.study-page-word-list-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 17px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
}

.study-page-word-list-word {
    width: 100%;
    background-color: white;
}

.study-page-word-list-word > p {
    font-size: 17px;
    font-weight: 600;
}

.word-blind {
    color: transparent;
    text-shadow: 0 0 18px rgb(0 0 0);
    cursor: pointer;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.25) 10px,
            rgba(255, 255, 255, 0.25) 20px
    );
}

.study-page-word-list-btn {
    height: 22px;
    display: flex;

}

.study-page-word-list-btn > svg {
    cursor: pointer;
    margin-left: 10px;
}

/* 목록 리스트 버튼 박스 */
.change-position-box {
    position: fixed;
    bottom: 20px; /* 기본적으로 화면 하단에 고정 */
    left: 50%;
    transform: translateX(-50%) translateY(100%); /* 처음에는 화면 밖에 위치 */
    opacity: 0; /* 투명하게 시작 */
    visibility: hidden; /* 요소가 보이지 않도록 설정 */
    padding: 15px 20px;
    background-color: white;
    border: none;
    border-radius: 15px;
    z-index: 1;
    box-shadow: 0px 0px 11px rgba(23, 12, 7, 0.13), 0 0px 0px rgba(0, 0, 0, 0);
    width: 270px;
    height: 80px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* 애니메이션 적용 */
}

.change-position-box.change-position-show {
    visibility: visible; /* 요소가 보이도록 설정 */
    transform: translateX(-50%) translateY(0); /* 화면 안으로 위치 */
    opacity: 1; /* 완전히 보이도록 변경 */
}

.absolute-button {
    position: absolute;
    bottom: -95px; /* 마지막 아이템 하단에 배치 */
    right: 20px;
}

.study-white-box {
    height: 106px;
}

.change-btn-box-position {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #d7dbe6;
    border-radius: 10px;
}

.change-position-var {
    background: #d7dbe6;
    height: 1.5rem;
    width: .0625rem;
}

.change-position-text {
    font-size: 16px;
    font-weight: 700;
    width: 80%;
    height: 100%;
    text-align: center;
    color: #586380;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.change-position-text:hover {
    background-color: #d0d0ff;
}

.change-position-btn {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.change-position-btn:hover {
    background-color: #d0d0ff;
}

.only-content > p,
.only-word > p {
    padding-bottom: 27px;
}


/* 500 이하 */
@media (max-width: 500px) {
    .study-page-mid {
        width: 95%;
        margin-top: 20px;
    }

    .on-word-box {
        height: 230px;
        font-size: 40px;
        font-weight: 400;
    }

    .chinese-block-box {
        height: 60px;
    }

    .help-box {
        font-size: 13px;
    }

    .study-page-word-list-word {
        display: flex;
        flex-direction: column;

    }

    .study-level-box {
        width: 70%;
    }

    .study-level-box > div {
        width: 25%;
    }
}

/* 500 이상 */
@media (min-width: 500px) {
    .study-page-mid {
        width: 90%;
        margin-top: 30px;
    }

    .on-word-box {
        height: 280px;
        font-size: 45px;
        font-weight: 400;
    }

    .chinese-block-box {
        height: 67px;
    }

    .help-box {
        font-size: 15px;
    }

    .study-page-word-list-word {
        display: flex;
        flex-direction: row;
    }

    .study-page-word-list-word-content {
        width: 40%;
    }

    .study-level-box {
        width: 60%;
    }

    .study-level-box > div {
        width: 30%;
    }
}

/* 1000 이상 */
@media (min-width: 1000px) {
    .study-page-mid {
        width: 70%;
        margin-top: 40px;
    }

    .on-word-box {
        height: 330px;
        font-size: 50px;
        font-weight: 400;
    }

    .chinese-block-box {
        height: 75px;
    }

    .help-box {
        font-size: 16px;
    }

    .study-level-box {
        width: 50%;
    }

    .study-level-box > div {
        width: 30%;
    }
}



