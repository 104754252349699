.set-study-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}


/* 나의 학습으로 box */
.set-study-my-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 30px 0;
}

.set-study-my-page button {
    width: 60%;
    height: 50px;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    border: 2px solid #4255ff;
    background-color: #4255ff;
    color: white;
}

.set-study-my-page button:hover {
    border: 2px solid #4c16f9;
    background-color: #4c16f9;
}


.set-study-setting-box > svg:hover {
    color: red;
}


/* 500 이하 */
@media (max-width: 500px) {
    .set-study-all {
        width: 95%;
        margin-top: 20px;
    }


}

/* 500 이상 */
@media (min-width: 500px) {
    .set-study-all {
        width: 90%;
        margin-top: 30px;
    }


}

/* 1000 이상 */
@media (min-width: 1000px) {
    .set-study-all {
        width: 70%;
        margin-top: 40px;
    }


}
