.main-favorite-note-list-container {
    padding: 20px 0;
}

.main-favorite-note-content-all {
    display: grid;
    gap: 15px;
    margin-bottom: 30px;
}

/* 컨텐트 박스 */
.main-favorite-note-content-box {
    cursor: pointer;
    border: 2px solid #edeff4;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1), 0 0px 0px rgba(0, 0, 0, 0);
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    position: relative;
}

.main-favorite-note-content-box:hover {
    border: 2px solid #bbc4fbab;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.15), 0 0px 0px rgba(0, 0, 0, 0);
}

.solo-study-content-add-box {
    cursor: pointer;
    border: 2px solid #edeff4;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1), 0 0px 0px rgba(0, 0, 0, 0);
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 152px;
}

.solo-study-content-add-box:hover {
    border: 2px solid #bbc4fbab;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.15), 0 0px 0px rgba(0, 0, 0, 0);
}


.main-favorite-note-content-title {
    font-weight: 600;
    margin-bottom: 10px;
}

.main-favorite-note-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    width: 57px;
    height: 23px;
    background-color: rgb(60 106 251 / 31%);
}

.main-favorite-note-write {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.main-favorite-note-write img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.main-favorite-note-setting-box {
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    display: flex;
    align-items: center;
}

.main-favorite-note-setting-box > svg {
    margin-left: 5px;
}

.main-favorite-note-setting-box > p {
    font-size: 14px;
    font-weight: 500;
}

/* 뒷 배경 흐리게 */
.main-favorite-note-modal-back-ground {
    width: 100%;
    height: 100%;
}

.main-favorite-note-modal-back-ground::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 11, 35, 0.5); /* 배경 어둡게 */
    z-index: 1001;
    backdrop-filter: blur(1px); /* 배경 흐리게 */
}

.main-favorite-note-modal-all {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1);
    border-radius: 10px;
    z-index: 1002;
    padding: 24px 32px 9px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.main-favorite-note-modal-all > div {
    width: 100%;
    border: 2px solid #edeff5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #212121;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);

}

.main-favorite-note-modal-all > div:hover {
    border: 2px solid #bbc4fbab;
}
.main-favorite-note-modal-all > div:hover p{
    border-bottom: 4px solid #dc96fd;
    color: #dc96fd;
}

.main-favorite-note-modal-all > div > p {
    padding-bottom: 7px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}
.main-favorite-note-modal-move > p {
    border-bottom: 4px solid #2fb7f6;
}

.solo-study-modal-modify > p {
    border-bottom: 4px solid #c6f16c;

}

.solo-study-modal-remove > p {
    border-bottom: 4px solid #ff8247;
}

.main-favorite-note-modal-all > button {
    width: 100%;
    height: 50px;
    border: 2px solid #edeff5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #56607c;
    background-color: #ebebeb;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.main-favorite-note-modal-all > button:hover {
    background-color: #b1b1b1ab;
}


/* 500 이하 */
@media (max-width: 500px) {
    .main-favorite-note-list-container{
        width: 380px;
    }

    .main-favorite-note-content-all {
        grid-template-columns: repeat(2, 1fr);
    }

    .main-favorite-note-modal-all {
        width: 95%;
    }
    .main-favorite-note-setting-box {
        display: none;
    }
    .main-favorite-note-count {
        margin-bottom: 15px;
        font-size: 11px;
    }
    .main-favorite-note-content-title {
        font-size: 14px;
    }
    .main-favorite-note-count {
        width: 50px;
    }
    .main-favorite-note-write {
        font-size: 12px;
    }
}

/* 500 이상 */
@media (min-width: 500px) {
    .main-favorite-note-list-container{
        width: 480px;
    }

    .main-favorite-note-content-all {
        grid-template-columns: repeat(2, 1fr);
    }

    .main-favorite-note-modal-all {
        width: 80%;
    }

    .main-favorite-note-count {
        margin-bottom: 25px;
    }
    .main-favorite-note-content-title {
        font-size: 16px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .main-favorite-note-list-container{
        width: 980px;
    }

    .main-favorite-note-content-all {
        grid-template-columns: repeat(3, 1fr);
    }

    .main-favorite-note-modal-all {
        width: 30%;
    }
    .main-favorite-note-count {
        margin-bottom: 35px;
    }
}