.withdrawal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 30px 0;
}

.withdrawal-box-all {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.withdrawal-title-box {
    justify-content: center;
    align-items: center;
    position: absolute;
}

.withdrawal-title-box > img {
    width: 250px;
    height: 250px;
}

.withdrawal-info {
    margin-top: 10px;
}

.withdrawal-content-box {
    height: auto;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-top: 20px;
    background-color: #f9fafb;
    color: #606060;
}

.withdrawal-content > p {
    line-height: 20px;
}

.depth-one {
    padding-left: 7px;
    margin-top: 15px;
}

.depth-two {
    padding-left: 14px;
    margin-top: 5px;
}

.withdrawal-checkbox > label {
    display: flex;
    margin-top: 20px;
}

.withdrawal-checkbox > label > input {
    width: 20px;
    height: 20px;
}

.withdrawal-checkbox > label > span {
    margin-left: 6px;
}

.withdrawal-checkbox strong {
    color: #40A578;
}

.withdrawal-btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.withdrawal-btn-box > button {
    border: none;
    width: 49%;
    height: 50px;
    font-weight: 700;
}

.withdrawal-btn-box > .withdrawal-close-btn {
    color: #667085;
    background-color: #f2f4f7;
    transition: border 0.3s ease, background-color 0.3s ease;
}

.withdrawal-btn-box > .withdrawal-close-btn:hover {
    background-color: #b4b4b4;

}


.withdrawal-check-on {
    background-color: #9DDE8B;
    color: white;
    transition: border 0.3s ease, background-color 0.3s ease;
}
.withdrawal-check-on:hover {
    background-color: #85dc6d;
}

.withdrawal-check-off {
    background-color: #e1e1e1;
    color: white;

}

/* 반응형 */

/* 600 이상 */
@media (max-width: 600px) {
    .withdrawal-box-all {
        width: 300px;
        margin-top: 90px;
    }

    .withdrawal-title-box {
        top: 0;
    }


    .withdrawal-info > p {
        font-size: 18px;
        font-weight: 700;
    }

    .withdrawal-content-box {
        width: 352px;
        padding: 15px;
    }

    .withdrawal-content > p {
        font-size: 14px;
    }

    .withdrawal-checkbox {
        width: 352px;
    }

    .withdrawal-btn-box {
        width: 352px;
    }

    .withdrawal-btn-box > button {
        font-size: 14px;
    }
}

/* 600 이상 */
@media (min-width: 600px) {
    .withdrawal-box-all {
        width: 500px;
        margin-top: 150px;
    }
    .withdrawal-title-box {
        top: 50px;
    }
    .withdrawal-info > p {
        font-size: 20px;
        font-weight: 700;
    }

    .withdrawal-content-box {
        width: 452px;
        padding: 25px;
    }

    .withdrawal-content > p {
        font-size: 14px;
    }

    .withdrawal-checkbox {
        width: 452px;
    }

    .withdrawal-btn-box {
        width: 452px;
    }

    .withdrawal-btn-box > button {
        font-size: 15px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .withdrawal-box-all {
        width: 850px;
        margin-top: 150px;
    }
    .withdrawal-title-box {
        top: 70px;
    }


    .withdrawal-info > p {
        font-size: 22px;
        font-weight: 700;
    }

    .withdrawal-content-box {
        width: 682px;
        padding: 30px;
    }

    .withdrawal-content > p {
        font-size: 16px;
    }

    .withdrawal-checkbox {
        width: 682px;
    }

    .withdrawal-btn-box {
        width: 682px;
    }

    .withdrawal-btn-box > button {
        font-size: 17px;
    }
}