.policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.policy-container p {
    line-height: 24px;
}

.policy-title > h2 {
    text-align: center;
    font-size: 35px;
    margin: 30px 0;
    
}

.policy-detail-box {
    background-color: #f5f5f5;
    color: #666;
    padding: 20px;
    margin: 16px 0;
    font-size: 15px;
}

.policy-detail-subBox {
    background-color: #fff;
    color: #666;
    padding: 12px 16px;
    margin: 16px 0;
}

.policy-detail-title {
    font-size: 24px;
    font-weight: bold;
    color: #2eb9f5;
    margin: 48px 0;
}

.policy-detail-subTitle {
    font-size: 17px;
    font-weight: 500;
    margin: 20px 0 10px 0;
}

.policy-detail-content {
    font-size: 15px;
    color: #666;
}

/* 600 이하 */
@media(max-width : 600px){
    .policy-box{
        width: 355px;
    }
}

/* 600 이상 */
@media(min-width : 600px){
    .policy-box{
        width: 600px;
    }
}
/* 1000 이상 */
@media(min-width : 1000px){
    .policy-box{
        width: 1000px;
    }
}