footer {
    background-color: white;
}

.footer-box {
    padding: 15px 30px 15px 30px;
    background-color: white;
    border-top: 1px solid #d9d4d4;
}

.footer-title h3 {
    font-size: 58px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black;
    width: 100%;
}

.footer-content-box {
    margin-bottom: 15px;
    color: #353535;
}


.footer-content-box p,
.footer-info-box p {
    margin-bottom: 5px;
    line-height: 25px;
    color: black;
}

.footer-info-box {
    color: #353535;
}

.footer-content-box > p {
    cursor: pointer;
}
/* 500 이하 */
@media (max-width: 500px) {

}

/* 500 이상 */
@media (min-width: 500px) {
    .footer-box {
        overflow: hidden;
    }

    .footer-title {
        float: left;
        width: 74%;
    }

    .footer-title h3 {
        font-size: 80px;
        margin-bottom: 55px;
    }

    .footer-content-box {
        float: right;
        margin-top: 16px;
    }

    .footer-info-box {
        float: left;
    }
}


/* 1000 이상 */
@media (min-width: 1000px) {
    .footer-box {
        overflow: hidden;
    }

    .footer-title {
        float: left;
        width: 85%;
    }

    .footer-title h3 {
        font-size: 100px;
        margin-bottom: 55px;
    }

    .footer-content-box {
        float: right;
        margin-top: 16px;
    }

    .footer-info-box {
        float: left;
    }
}
