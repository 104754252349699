.comment-button {
    margin-left: 10px;
}

.detail-box-margin-bottom {
    margin-bottom: 15px
}

.inquiry-write-content-box {
    width: 100%;
}