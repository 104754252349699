.result-page-all {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  background-color: #f6f7fb;
  padding-bottom: 60px;
}

.result-page-mid{
  background-color: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.0);
}

.result-off-box{
  display: flex;
  flex-direction:column;
  align-items: center;
  padding: 40px 0 35px 0;
}

.result-off-info > h5 {
  padding-bottom: 5px;
}

.result-off-info {
  margin-bottom: 20px;

}
.result-off-req-box > input{
  width: 50px;
  height: 23px;
  text-align: center;
  font-size: 13px;
  margin-right: 5px;
}
.result-off-req-box > button{
  width: 80px;
  height: 40px;
  background-color: #37bd5d;
  border-style: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.result-box-content{
  transition: transform 0.3s ease; 
}
.result-box-content.fade-out {
  transform: rotateX(360deg); 
}

/* 500 이하 */
@media(max-width : 600px){
  .result-page-mid{
    width: 80vw;
  }
  .result-off-info {
    font-size: 15px;
  }
}

/* 500 이상 */
@media(min-width : 600px){
  .result-page-mid{
    width: 65vw;
  }
  .result-off-info {
    font-size: 18px;
  }
}
 /* 1000 이상 */
@media(min-width : 1000px){
  .result-page-mid{
    width: 43vw;
  }
  .result-off-info {
    font-size: 24px;
  }
}


.result-on-box {
  padding: 30px 30px 20px 30px;
}

.result-on-box .result-on-header-box{
  text-align: center;
  margin-bottom: 20px;
}

.result-box-content {
  border: 1px solid #777;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.0);

}

.result-on-box .result-on-header-box > p {
  font-size: 25px;
  font-weight: 500;
}

.result-header-box{
  display: flex;
  justify-content: space-between;
}
.result-word-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-input-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-input-box > input {
    border-style: none;
    border-bottom: 1px solid #777;
    text-align: center;
    font-size: 20px;
    padding-bottom : 7px;
    margin-bottom: 15px;
    outline: none;
    background-color: transparent;
}


.result-on-header-box > h3{
  font-size: 30px;
}




/* 500 이하 */
@media(max-width : 600px){
  .result-word-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    font-size: 30px;
  }
}

/* 500 이상 */
@media(min-width : 600px){
  .result-word-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 40px;
  }
}

.submit-box{
  display: flex;
  justify-content: center;
}
.submit-box > button {
  height: 50px;
  width: 110px;
  border-style: none;
  border-radius: 10px;
  background-color: #6CC4A1;
  color: white;
  font-size: 15px;
  font-weight: 600;
  margin: 0 5px;
}

.success {
  background-color: #5ed580;
}
.fail{
  background-color: #ff4f4f;
}

/* content + 한자  */
.test-word-content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.test-word-content-box > .test-word-content-chinese {
  font-size: 25px;
}