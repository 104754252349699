.inquiry-write-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.inquiry-write-box input {
    height: 45px;
    padding-left: 7px;
    border-radius: 4px;
    border: 1px solid #777;
    font-size: 14px;
}

.user-inquiry-write-content-box {
    width: 100%;
    margin-bottom: 10px;
}

.inquiry-write-box input {
    width: 100%;
}

.user-inquiry-title-box {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 66px;
    border-bottom: 3px solid #2668f1;
}

/* 내용 입력 */
.inquiry-write-title-detail p,
.inquiry-write-writer-box p,
.inquiry-write-email-detail p,
.inquiry-write-password-box p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.inquiry-write-title-detail input,
.inquiry-write-writer-box input,
.inquiry-write-email-detail input,
.inquiry-write-password-detail input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    font-size: 15px;
    margin-bottom: 20px;
}
.inquiry-write-password-check-detail input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    font-size: 15px;
}

/* check box */
.inquiry-checkbox {
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, 0.12);
    z-index: 1;
    padding-bottom: 15px;
}

.inquiry-checkbox > label {
    display: flex;
    margin-top: 20px;
}

.inquiry-checkbox > label > input {
    width: 20px;
    height: 20px;
}

.inquiry-checkbox > label > span {
    margin-left: 6px;
}

.inquiry-checkbox strong {
    color: #40A578;
}


/* 로그인한 상태일 때 background 색상 변경*/
.input-backGround {
    background-color: #e8e8e8a6;
    outline: none;
}


/* 반응형 */
/* 600 까지 */
@media (max-width: 600px) {
    .inquiry-write-box {
        margin-top: 40px;
        width: 95%;
    }

}

/* 600 이상 */
@media (min-width: 600px) {
    .inquiry-write-box {
        margin-top: 40px;
        width: 90%;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .inquiry-write-box {
        margin-top: 40px;
        width: 70%;
    }


}