.recordPage-page-all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.recordPage-page-mid .recordPage-info {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
}

.recordPage-page-mid .recordPage-info > p {
    border-bottom: 3px solid #2668f1;
    display: inline-block;
}

/* 카테고리 박스 */
.recordPage-category-box {
    display: flex;
    gap: 10px;
    height: 30px;
    margin: 10px 0 30px 0;
    border-bottom: 2px solid #cfcfcf;
}

.recordPage-category-box > div {
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0 0 10px 0;
    font-size: 15px;
}

.recordPage-category-box-not-choice {
    color: #777777;
}

.recordPage-category-box-not-choice:hover {
    font-weight: 500;
    color: black;

}

.recordPage-category-box-choice {
    color: black;
    font-weight: 500;
}

.recordPage-category-box-choice::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #32b1ea;
}

.recordPage-score {
    border: 1px solid #32b1ea;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: 0 0.125rem 0.25rem #00000014;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.recordPage-score:hover {
    background-color: rgb(177 229 237 / 35%);
}

.recordPage-score .score-header {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
}

.recordPage-score .score-header > div {
    margin-right: 3px;
}

.recordPage-score .score-content {
    display: flex;
    font-size: 17px;
    justify-content: space-between;

}

.recordPage-score-delete-btn {
    position: absolute;
    top: 4px;
    right: -2px;
    cursor: pointer;
}

/* 페이지 네이션*/
.data-detail-box-all {
    display: flex;
    justify-content: center;
}

.data-pageNation {
    width: 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

/* 500 이하 */
@media (max-width: 600px) {
    .recordPage-page-mid {
        width: 95%;
        margin-top: 20px;
    }

}

/* 500 이상 */
@media (min-width: 600px) {
    .recordPage-page-mid {
        width: 90%;
        margin-top: 30px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .recordPage-page-mid {
        width: 70%;
        margin-top: 40px;
    }

}