.choice-test-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 0 5px;
    background-color: white;
    width: 100%;
    position: relative;

}

.choice-test-all {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.choice-test-title-box {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
}

.choice-test-title-box > p {
    padding-bottom: 5px;
    border-bottom: 3px solid #2668f1;
}

.choice-test-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    position: fixed;
    z-index: 3;
}

.choice-test-header > p {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 10px;
}

/* Progress bar container */
.choice-test-progress-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 10px;
}

/* Fill element */
.choice-test-progress-bar-fill {
    height: 100%;
    background-color: #2ac4f8;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

/* content box */
.choice-test-content-box {
    background-color: white;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
    border-radius: 10px;
    padding: 20px 32px 16px;
    margin-bottom: 50px;
    position: relative;
}

.choice-test-content-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.choice-test-content-header-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.choice-test-content-header-left > p {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}

.choice-test-content-header-left svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.choice-test-content-header-right > p {
    font-size: 14px;
    font-weight: 600;
    color: #777777;
}


/* 뜻 box */
.choice-test-content-content-box {
    margin-bottom: 30px;
}

.choice-test-content-content-box > .choice-test-content-word-content {
    font-size: 23px;
    margin-bottom: 75px;
}

.choice-test-content-content-box > .choice-test-content-info {
    font-size: 14px;
    font-weight: 650;
    color: rgb(86, 96, 124);
}

.choice-test-content-content-box > .choice-test-content-info-ok {
    font-size: 14px;
    font-weight: 650;
    color: rgb(0 157 1);
}

.choice-test-content-content-box > .choice-test-content-info-fail {
    font-size: 14px;
    font-weight: 650;
    color: rgb(244 73 73);
}

/* 단어 목록 box */

.choice-test-content-choice-example-box {
    border: 2px solid rgb(237 239 245);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.choice-test-content-choice-example-box:hover {
    background-color: #d0d0ff;
}

.choice-test-example-index {
    padding: 2px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    border-radius: 50%;
    background-color: #e3e5eb;
    margin-right: 10px;

}

.choice-test-example-word {
    font-size: 20px;
    font-weight: 600;
}

/* i do not know*/
.choice-test-content-i-do-not-know-box {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.choice-test-content-i-do-not-know-box > div {
    width: 135px;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #4255ff;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.choice-test-content-i-do-not-know-box > div:hover {
    background-color: #e5eaff7d;
}


.choice-test-content-choice-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* 간격 설정 */
}

.choice-test-content-choice-example-box {
    flex: 1 1 100%; /* 기본적으로 100% 차지 (한 줄에 1개) */
}

@media (min-width: 1000px) {
    .choice-test-content-choice-example-box {
        flex: 1 1 calc(50% - 20px); /* 1000px 이상일 때 한 줄에 2개 */
    }
}


/* 정답 클릭 */
.choice-text-check-answer {
    border: 2px solid #7cb1ef;
    background-color: #c7dcf5;
}


/* 정답, 오답 */
.test-answer-ok-box {
    border: 2px #4cbc5c dashed;
    background-color: #c3ffcd70;

}

.test-answer-fail-box {
    border: 2px #ff0000bf dashed;
    background-color: #ffc4c470;
}

/** 채점 후 페이지 **/
/* 최상단 점수 내용 */
.choice-test-answer-title {
    margin-bottom: 15px;

}

.choice-test-answer-title-point {
    font-weight: 600;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.choice-test-answer-title-content {
    font-weight: 700;
}

.choice-test-result-data-box {
    display: flex;
    margin-bottom: 15px;
}


.choice-result-data-graph-title,
.choice-result-recommend-title {
    font-size: 18px;
    font-weight: 700;
    color: #929bb4;
    margin-bottom: 15px;
}

.choice-result-data-graph-content {
    width: 100%;
    display: flex;
    justify-content: start;
}

.choice-result-data-graph {
    width: 100px;
    padding-left: 5px;
}

.choice-result-data-graph-svg {
    width: 80px;
    height: 80px;
}

.choice-result-data-point {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.choice-result-data-point {
    font-size: 17px;
    font-weight: 700;
    padding-left: 12px;
}

.choice-result-data-point > div {
    width: 100%;
    display: flex;
    margin-bottom: 3px;
}

.choice-result-data-point-title {
    margin-right: 35px;
}

.choice-result-data-point-count {
    border-radius: 20px;
    width: 44px;
    text-align: center;
}

.choice-result-data-point-ok-box {
    color: #4cbc5c;
}

.choice-result-data-point-ok-box > .choice-result-data-point-count {
    border: 1px solid #4cbc5c;
}

.choice-result-data-point-fail-box {
    color: rgb(255 159 89);
}

.choice-result-data-point-fail-box > .choice-result-data-point-count {
    border: 1px solid rgb(255 159 89);
}

.choice-result-recommend-box img {
    width: 35px;
    height: 35px;
    margin-right: 13px;
}

.choice-result-recommend-link-box {
    border: 1px solid #ecebeb;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.14), 0 0px 0px rgba(0, 0, 0, 0);
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.choice-result-recommend-link-image {
    display: flex;
}

.choice-result-recommend-link-title {
    font-size: 15px;
    font-weight: 700;
    color: #6e7be9;
}

.choice-result-recommend-link-content {
    font-size: 15px;
    font-weight: 500;
}

.choice-result-recommend-link-box:hover {
    border: 1px solid #9da9f3;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.3), 0 0px 0px rgba(0, 0, 0, 0);
}

/* 정답 문구 */
.choice-result-content-ok-box {
    font-size: 14px;
    font-weight: 650;
    color: rgb(0 157 1);
}

.choice-result-content-fail-box {
    font-size: 14px;
    font-weight: 650;
    color: rgb(244 73 73);
}


.choice-test-side-title-box {
    font-size: 15px;
    font-weight: 700;
    color: #4255ffb8;
    margin-bottom: 5px;
}

.choice-test-side-content-choice-box {
    font-size: 15px;
    font-weight: 600;
    color: #818181;
    margin-bottom: 5px;
    display: flex;

}

.choice-test-side-content-test-number {
    width: 30px;
    color: #818181;
}

.choice-test-side-content-test-choice {
    font-size: 15px;
    font-weight: 600;
    color: #4a4a4a;

}

.choice-test-side-toggle-box > svg {
    position: absolute;
    right: 15px;
    top: 15px;
}

.choice-test-side-title-box {
    margin-top: 20px;
}

/* 버튼 박스 */
.choice-test-btn-box {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.choice-test-btn-box > button {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    width: 47%;
}

.choice-test-btn-home {
    border: 2px solid #d9dde8;
    background-color: white;
    color: #56607c;
}

.choice-test-btn-home:hover {
    background-color: #e4e5e587;
}

.choice-test-btn-submit {
    border: 2px solid #4255ff;
    background-color: #4255ff;
    color: white;
}

.choice-test-btn-submit:hover {
    border: 2px solid #4c16f9;
    background-color: #4c16f9;
}

/* 500 이하 */
@media (max-width: 500px) {
    .choice-test-all {
        width: 95%;
    }

    .choice-test-all-margin-top {
        margin-top: 15px;
    }

    .choice-test-content-box,
    .choice-test-answer-title,
    .choice-test-result-data-box {
        width: 100%;
    }

    .choice-test-header {
        top: 48px;
    }

    .choice-test-result-data-box {
        flex-direction: column;
    }

    .choice-result-data-point {
        width: 70%;
    }

    .choice-result-data-graph-box,
    .choice-result-recommend-box {
        width: 100%;
    }

    .choice-result-data-graph-box {
        margin-bottom: 20px;
    }

    .choice-test-answer-title-content {
        font-size: 15px;
    }

    /* 사이드 바 */
    .choice-test-side-toggle-box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #63687ac7;
        margin-bottom: 12px;
        cursor: pointer;
    }

    .choice-test-side-toggle-box > svg {
        margin-right: 5px;
    }

    .choice-test-side-box {
        border: 1px solid #dcdcdc;
        border-radius: 8px 8px 0 0;
        padding: 3px 20px;
        background-color: white;
        box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
        position: fixed;
        bottom: 0;
        width: 360px;
        transition: transform 0.5s ease-in-out;
        transform: translateY(150%);
    }

    .choice-test-side-toggle-box {
        padding-top: 20px;
    }

    .choice-test-side-toggle-box,
    .choice-test-side-title-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .choice-test-side-content-box {
        padding: 0 138px;
    }

    .choice-test-side-box.side-box-visible {
        transform: translateY(331px);
        transition: all .3s cubic-bezier(.47, 0, .745, .715);
        overflow: scroll;
        max-height: 350px;
    }

    .choice-test-side-box.side-box-hidden {
        transform: translateY(15px);
        padding-bottom: 10px;
        max-height: 374px;
        overflow: scroll;
    }

    .choice-test-side-box.side-box-visible:hover {
        width: 360px;
        height: auto;
        box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.7), 0 0px 0px rgba(0, 0, 0, 0);
    }

    .choice-test-side-on-btn {
        display: none;
    }

}


/* 500 이상 */
@media (min-width: 500px) {
    .choice-test-all {
        width: 90%;

    }

    .choice-test-all-margin-top {
        margin-top: 15px;
    }

    .choice-test-content-box,
    .choice-test-answer-title,
    .choice-test-result-data-box {
        width: 100%;
    }

    .choice-test-result-data-box {
        flex-direction: column;
    }

    .choice-result-data-point {
        width: 70%;
    }

    .choice-result-data-graph-box,
    .choice-result-recommend-box {
        width: 100%;
    }

    .choice-result-data-graph-box {
        margin-bottom: 20px;
    }

    .choice-test-answer-title-content {
        font-size: 30px;
    }

    /* 사이드 바 */
    .choice-test-side-toggle-box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #63687ac7;
        margin-bottom: 12px;
        cursor: pointer;
    }

    .choice-test-side-toggle-box > svg {
        margin-right: 5px;
    }

    .choice-test-side-box {
        border: 1px solid #dcdcdc;
        border-radius: 8px 8px 0 0;
        padding: 3px 20px;
        background-color: white;
        box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
        position: fixed;
        width: 360px;
        transition: transform 0.5s ease-in-out;
        transform: translateY(150%);
        bottom: 0;
    }

    .choice-test-side-toggle-box {
        padding-top: 20px;
    }

    .choice-test-side-toggle-box,
    .choice-test-side-title-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .choice-test-side-content-box {
        padding: 0 138px;
    }

    .choice-test-side-box.side-box-visible {
        transform: translateY(342px);
        transition: all .3s cubic-bezier(.47, 0, .745, .715);
        overflow: scroll;
        max-height: 360px;
    }

    .choice-test-side-box.side-box-hidden {
        transform: translateY(15px);
        padding-bottom: 10px;
        overflow: scroll;
        max-height: 374px;
    }

    .choice-test-side-box.side-box-visible:hover {
        width: 362px;
        box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.7), 0 0px 0px rgba(0, 0, 0, 0);
    }

    .choice-test-side-on-btn {
        display: none;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .choice-test-all {
        width: 70%;
    }

    .choice-test-content-box,
    .choice-test-answer-title,
    .choice-test-result-data-box {
        width: 770px;
    }

    .choice-test-header {
        top: 72px;
    }

    .choice-result-data-point {
        width: 43%;
    }

    .choice-test-result-data-box {
        flex-direction: row;
    }

    .choice-result-data-graph-box {
        margin-bottom: 0;
    }

    .choice-test-side-box {
        position: fixed;
        top: 95px;
        left: 10px;
        width: 10%;
        max-height: 630px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(-150%);
    }

    .choice-test-side-box.side-box-visible {
        transform: translateX(0);
    }

    .choice-test-side-on-btn {
        position: fixed;
        top: 93px;
        left: 20px;
        cursor: pointer;
    }

    .choice-test-side-toggle-box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #63687ac7;
        margin-bottom: 12px;
        cursor: pointer;
        padding-top: 10px;

    }

    .choice-test-side-toggle-box > svg {
        margin-right: 5px;
    }

    .choice-test-side-content-box {
        padding: 0;
    }

    .choice-test-side-toggle-box,
    .choice-test-side-title-box {
        justify-content: center;
    }

    .choice-test-side-box.side-box-visible {
        transform: translateY(0px);
        border-radius: 8px;
        overflow: auto;
        padding-bottom: 10px;
        max-height: 350px;
    }

    .choice-test-side-box.side-box-visible:hover {
        width: 10%;
    }

    .side-box-hidden.side-box-hidden {
        transform: translateX(-150%);
        max-height: 350px;
        overflow: hidden;
    }

    .choice-test-side-on-btn {
        display: block;
    }

}

.side-box-visible::-webkit-scrollbar,
.side-box-hidden::-webkit-scrollbar {
    width: 5px; /* 스크롤바의 너비 */
}

.side-box-visible::-webkit-scrollbar-thumb,
.side-box-hidden::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: #5b9dfa; /* 스크롤바의 색상 */
    border-radius: 10px;
}

.side-box-visible::-webkit-scrollbar-track,
.side-box-hidden::-webkit-scrollbar-track {
    background: rgba(64, 145, 255, 0.35); /*스크롤바 뒷 배경 색상*/
}