.mypage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.mypage-content-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mypage-title-box {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.mypage-image-box {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user-icon {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    border-radius: 50%;
}

.user-icon-modify-btn {
    color: transparent;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.user-icon-modify-btn:hover {
    background-color: rgba(158, 158, 158, 0.24);
    color: #4a4a4a;
}

.mypage-btn-box-on,
.mypage-btn-box-off {
    width: 360px;
    margin-bottom: 30px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.mypage-btn-box-off {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mypage-btn-box-off > button {
    width: 45%;
    border-radius: 8px;
    background-color: #4255ff;
    color: white;
    height: 48px;
    font-size: 17px;
    border: none;
    margin: 20px 0;
}
.mypage-btn-box-on > button {
    width: 100%;
    border-radius: 8px;
    background-color: #4255ff;
    color: white;
    height: 48px;
    font-size: 17px;
    border: none;
    margin: 20px 0 5px 0;
}
.mypage-btn-box-on > button:hover,
.mypage-btn-box-off > button:hover {
    background-color: #4c16f9;
}


.mypage-title-box > p {
    border-bottom: 3px solid #2668f1;
}

.mypage-image-box > span {
    font-size: 20px;
    display: block;
    font-weight: 700;
}

.my-page-modify-password-back-ground {
    width: 100vw;
    height: 100vh;
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #3535359e;
    z-index: 1000;
}
.my-page-modify-password {
    z-index: 3;
    position:fixed;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: auto;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.0);
    display: flex;
    flex-direction: column;
    width: 360px;
}




.id-box,
.email-box,
.phone-box,
.password-box {
    width: 100%;
    margin-bottom: 20px;
}

.password-box {
    position: relative;
}

.password-change-btn {
    outline: 2px solid transparent;
    border: none;
    border-radius: 3px;
    background-color: #bcbcbc;
    color: white;
    outline-offset: 2px;
    width: 44px;
    height: 27px;
    font-size: 13px;
    padding: 5px 7px 3px;
    position: absolute;
    top: 35px;
    right: 13px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.password-change-btn:hover {
    background-color: #8c8b8b;
}

.mypage-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
}

.mypage-content p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.mypage-content input {
    height: 45px;
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    font-size: 15px;
    width: 100%;
}

/* 탈퇴 tag*/
.withdrawal-box {
    display: flex;
    align-items: center;
    justify-content: start;
}

.withdrawal-box > input {
    border: none;
    color: #2eb9f5;
    background-color: white;
    margin-left: 5px;
}

/* 반응형 */

/* 600 이상 */
@media (max-width: 600px) {
    .mypage-box {
        margin-top: 40px;
        width: 95%;
    }

}

/* 1000 이상 */
@media (min-width: 600px) {
    .mypage-box {
        margin-top: 40px;
        width: 90%;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .mypage-box {
        margin-top: 40px;
        width: 70%;
    }


}