.search-box-all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.search-box > .search-box-title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    width: auto;
}

.search-box > .search-box-title p {
    display: inline-block;
    border-bottom: 3px solid #2668f1;
}

.search-favorite-button {


}


.search-box-title {
    font-size: 23px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d2cece;
    padding: 0 5px 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.search-input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-input-box > svg {
    cursor: pointer;
}

.search-input-box > input {
    width: 140px;
    height: 30px;
    padding-left: 8px;
    border: 1px solid #777;
    border-radius: 5px;
    outline-color: #2fb7f6;
}

.search-input-box > svg {
    margin-left: 6px;
}

.search-word-all {
    border: 1px solid #6CC4A1;
    border-radius: 8px;
    padding: 5px 10px 10px 5px;
    box-shadow: 0 0.125rem 0.25rem #00000014;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    height: 97px;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
}

.search-word-all:hover {
    background-color: rgb(156 255 137 / 22%);
}

.search-word-content {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-word-meaning {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-notice-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* data box */

.search-favorite-data {
    display: flex;
    flex-direction: column;
}

/* 단어 박스 내용 */
.search-favorite-box {
    border: 1px solid #4e4f4f85;
    border-radius: 5px;
    padding: 15px 15px 3px 15px;
    margin-top: 15px;
    box-shadow: 0px 0px 3px rgba(15, 0, 2, 0.2), 0 0px 0px rgba(0, 0, 0, 0);
}

.search-favorite-data-top p:hover {
    cursor: pointer;
    color: #1e03f1;
}

.search-favorite-data-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.search-favorite-data-top-content {
    font-size: 22px;
    color: #2668f1;
    transition: all .20s cubic-bezier(.47, 0, .745, .715);
}

.search-favorite-data-top-audio-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-favorite-data-middle {
    border: 1px solid #8282824a;
    border-radius: 3px;
    padding: 10px;
    background-color: #f1f1f14a;
    margin-bottom: 20px;
    font-weight: 500;
}

.search-not-word-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 16px;
    font-weight: 500;
}

/* 예문 */
.search-word-data-example {
    border: 1px solid #5656564a;
    border-radius: 3px;
    padding: 10px;
    background-color: #cbcaca4a;
    margin-bottom: 20px;
    font-weight: 500;
}

.search-word-data-example-title {
    font-size: 12px;
    margin-right: 3px;
}
.search-word-data-example-box {
    margin-bottom: 3px;
}
.search-word-data-example-title-box {
    display: flex;
    align-items: center;
}

.search-word-data-example-title-box > svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.search-word-data-example-content,
.search-word-data-example-meaning {
    padding-left: 5px;
    font-size: 14px;
    word-wrap: break-word; /* 긴 단어도 줄바꿈 */
    word-break: break-word; /* 단어를 끊어서 줄바꿈 */
    overflow-wrap: break-word; /* 최신 브라우저 지원 */
}

/* 600 이하 */
@media (max-width: 600px) {
    .search-box {
        width: 95%;
        margin-top: 20px;
    }

    .favorite-data-top-content {
        font-weight: 500;
    }


}

/* 600 이상 */
@media (min-width: 600px) {
    .search-box {
        width: 90%;
        margin-top: 30px;
    }

    .favorite-data-top-content {
        font-weight: 600;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .search-box {
        width: 70%;
        margin-top: 40px;
    }


}