body {
    background-color: white !important;
}
.main-page {

}

.main-favorite-note-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.5;
    color: #0f1317;
    letter-spacing: -0.01em;
    word-break: keep-all;
}


/* 500 이하 */
@media (max-width: 500px) {

}


/* 500 이상 */
@media (min-width: 500px) {

}


/* 1000 이상 */
@media (min-width: 1000px) {
}

