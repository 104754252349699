.sort-test-content-input-box {
    gap: 10px;
}
.sort-test-content-input-box > input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 13px;
}

.sort-test-answer-wait-box {
    border: 2px solid #d1d1d1;
}

.sort-test-content-input-box > input:focus {
    outline: none;
    border: 2px solid #7cb1ef;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.sort-test-content-input-box > span {
    font-size: 14px;
    font-weight: 600;
}

.sort-test-content-answer-input {
    margin-bottom: 15px;
}

.choice-test-my-page {
    justify-content: center;
}