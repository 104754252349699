
.solo-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

/* 타이틀 박스 */
.solo-add-header-sub-title {
    font-size: 15px;
    font-weight: 600;
    color: #014c8c;
}

.solo-add-header {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
}

/* 제목 입력 박스 */
.solo-add-title-all {
    width: 100%;
    box-shadow: 0 0 15px rgba(30, 0, 45, 0.1), 0 0 0 rgba(0, 0, 0, 0);
    border-radius: 10px;
    border: 2px solid #edeff4;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    margin-bottom: 30px;
}

.solo-add-title-all > p {
    font-size: 14px;
    color: #6a738c;
    padding-left: 16px;
    padding-top: 4px;
}

.solo-add-title-all input {
    width: 100%;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
}

.solo-add-title-input-value {
    height: 32px;
    font-size: 16px;
    padding: 0 16px;
    margin-bottom: 3px;
}

.solo-add-title-input-not-value {
    height: 60px;
    font-size: 17px;
    padding: 4px 16px;
}

.solo-add-title-all:focus-within {
    border: 2px solid #5d9cff;
}


/* 직접 추가 박스 */
.solo-add-content-all-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.solo-add-content-all-title-box {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 500;
    color: #014c8c;
}


.solo-add-word-search {
    border: 2px solid #d9dde8;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    border-radius: 8px;
    color: #777777;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    background-color: #ffffff;
    cursor: pointer;
}

.solo-add-word-search:hover {
    border: 2px solid #5e9cff;
}

.solo-add-word-search > svg {
    margin-right: 6px;
}


.solo-add-word-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(30, 0, 45, 0.06), 0 0 0 rgba(0, 0, 0, 0);
    border-radius: 10px;
    border: 2px solid #edeff4;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.solo-add-word-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid #edeff5;
    font-size: 15px;
    font-weight: 600;
    color: #777777;
}

.solo-add-word-header > svg {
    cursor: pointer;
}

.solo-add-word-content {
    width: 100%;
    display: flex;
    padding: 12px 12px 24px;
}

.solo-add-word-content > div {
    padding: 12px 20px 0 12px;
    width: 100%;
}

.solo-add-word-content input {
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    border-bottom: 2px solid #777;
    font-size: 16px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.solo-add-word-content input:focus {
    border-bottom: 2px solid #5e9cff;
}

.solo-add-word-content p {
    font-size: 14px;
    margin-top: 3px;
    color: #939bb4;
}


/* 단어 추가 버튼 */
.solo-add-word-add-box {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(30, 0, 45, 0.06), 0 0 0 rgba(0, 0, 0, 0);
    border-radius: 10px;
    border: 2px solid #edeff4;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.solo-add-word-add-box p {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 7px;
    border-bottom: 4px solid #2fb7f6;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.solo-add-word-add-box:hover p {
    border-bottom: 4px solid #dc96fd;
    color: #dc96fd;
}

/* btn box */
.solo-add-btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.solo-add-btn-box > button {
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    width: 47%;
    padding: 12px;
    margin: 30px 0;
}

.solo-add-word-search-btn-box > button {
    margin: 10px 0;
}

.solo-add-btn-home {
    border: 2px solid #d9dde8;
    background-color: white;
    color: #56607c;
}

.solo-add-btn-home:hover {
    background-color: #e4e5e587;
}

.solo-add-btn-submit {
    border: 2px solid #4255ff;
    background-color: #4255ff;
    color: white;
}

.solo-add-btn-submit:hover {
    border: 2px solid #4c16f9;
    background-color: #4c16f9;
}


/* 단어 검색 box */
/* 뒷 배경 흐리게 */
.solo-add-word-search-back-ground {
    width: 100%;
    height: 100%;
}

.solo-add-word-search-back-ground::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 11, 35, 0.5); /* 배경 어둡게 */
    z-index: 1001;
    backdrop-filter: blur(1px); /* 배경 흐리게 */
}

.solo-add-word-search-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.1);
    border-radius: 10px;
    z-index: 1002;
    padding: 24px 32px;
}

.solo-add-word-search-title-box {
    border-bottom: 1px solid rgb(237 239 245);
    padding-bottom: 10px;
}

.solo-add-word-search-title {
    font-size: 18px;
    font-weight: 600;
    color: #56607c;
    margin-bottom: 5px;
}

.solo-add-word-search-title-content {
    font-weight: 700;
}

.solo-add-word-search-search-box {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.solo-add-word-search-search-box > svg {
    margin-right: 3px;
}

.solo-add-word-search-search-box > input {
    width: 80%;
    height: 40px;
    border: 2px solid rgb(214 215 219);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #4a4a4a;
    padding: 0 12px;
    outline: none;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    margin-right: 10px;
}

.solo-add-word-search-search-box > input:focus {
    border: 2px solid #5d9cff;
}

.solo-add-word-search-search-box > svg {
    color: rgb(114 114 114);
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.solo-add-word-search-search-box > svg:hover {
    color: #5d9cff;
}


/* 선택된 단어 목록 */
.solo-add-word-search-choice-sub-title {
    margin-top: 15px;
    font-size: 17px;
    font-weight: 600;
    color: #383838;
}

.solo-add-word-search-choice-box {
    display: flex; /* 가로로 정렬 */
    flex-wrap: nowrap; /* 가로로 넘치는 부분을 감싸지 않도록 설정 */
    overflow-x: auto; /* 가로 스크롤 활성화 */
    white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
    padding: 10px 0 0 0;
    gap: 10px; /* 단어 간 간격 조정 */
}

.add-word-search-choice-word {
    border: 2px solid #a9b7ff;
    border-radius: 8px;
    padding: 10px;
    width: 90px; /* 단어 박스의 최소 너비 설정 */
    flex-shrink: 0; /* 축소되지 않도록 설정 */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.add-word-search-choice-word p {
    white-space: nowrap; /* 줄바꿈 금지 */
    overflow: hidden; /* 넘치는 텍스트 숨기기 */
    text-overflow: ellipsis; /* 넘치는 부분을 ...으로 표시 */
    margin: 0; /* 여백 제거 */
    padding: 0; /* 여백 제거 */
    color: #4a4a4a;
}

.solo-add-word-search-choice-box::-webkit-scrollbar {
    height: 4px; /* 스크롤바 높이 설정 */
}

.solo-add-word-search-choice-box::-webkit-scrollbar-thumb {
    background-color: #849cfb; /* 스크롤바 색상 */
    border-radius: 10px;
}

.solo-add-word-search-choice-box::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 스크롤바 트랙 색상 */
}

.add-word-search-choice-word > svg {
    position: absolute;
    top: 3px;
    right: 3px;
    color: #4a4a4a;
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.add-word-search-choice-word > svg:hover {
    color: #f38181;
}

/* 단어 목록 */
.solo-add-word-search-choice-sub-title2 {
    margin-top: 15px;
    font-size: 17px;
    font-weight: 600;
    color: #383838;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.solo-add-word-search-choice-sub-title2 > div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.solo-add-word-search-choice-sub-title2 > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.solo-add-word-search-word {
    margin-left: 5px;
}
.solo-add-word-search-time:hover,
.solo-add-word-search-word:hover{
    color: #009ce4;
}

.solo-add-word-search-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.solo-add-word-search-data-content-box {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    border: 2px solid #d6d7db;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    box-shadow: 0px 0px 15px rgba(30, 0, 45, 0.05), 0 0px 0px rgba(0, 0, 0, 0);
    cursor: pointer;
}

.solo-add-word-search-data-content-box:hover {
    border: 2px solid #5d9cff;
}

.solo-add-word-search-data-content-meaning{
    width : 30%;
}

.solo-add-word-page-nation .notice-pageNation {
    margin : 10px 0 !important;
}

/* 즐겨찾기 목록 버튼 */
.solo-add-word-favorite-btn {
    margin-right: 8px;
    height: 30px;
    padding: 5px 10px;
    border: none;
    border-radius: 8px;
    background-color: #4ea8ff;
    color: white;
    cursor: pointer;
}

.solo-add-word-favorite-btn:hover {
    background-color: #168dff;
    transition: all .25s cubic-bezier(.47, 0, .745, .715);
}




/* 500 이하 */
@media (max-width: 500px) {
    .solo-add-all {
        width: 95%;
        margin-top: 20px;
    }

    .solo-add-word-content {
        flex-direction: column;
    }


    .solo-add-word-search-modal {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .solo-add-word-search-title-content {
        font-size: 21px;
    }

    .solo-add-word-search-data-content-box {
        flex-direction: column;
    }
    .solo-add-word-search-data-content-box {
        padding: 4px 10px 4px 16px;
    }


}

/* 500 이상 */
@media (min-width: 500px) {
    .solo-add-all {
        width: 90%;
        margin-top: 30px;
    }

    .solo-add-word-content {
        flex-direction: column;
    }

    .solo-add-word-search-modal {
        width: 90%;
    }

    .solo-add-word-search-title-content {
        font-size: 27px;
    }
    .solo-add-word-search-data-content-box {
        padding: 10px 10px 10px 16px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .solo-add-all {
        width: 70%;
        margin-top: 40px;
    }

    .solo-add-word-content {
        flex-direction: row;
        justify-content: space-between;
    }

    .solo-add-word-search-modal {
        width: 50%;
    }
}