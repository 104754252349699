.join-info-box, .join-info {
    width: 100%;
}

/* 소셜 로그인 */
.social-join {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.social-title > p {
    font-size: 21px;
    font-weight: 800;
    margin-bottom: 10px;
}

.social-join img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
}


.join-hr {
    border: 0.1px solid #d9d4d4;
    margin: 20px 0;
    width: 100%;
}


/* input box */
.join-input-box p {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.join-input-box input {
    height: 45px;
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    font-size: 15px;
    outline-color : #2fb7f6;
}
.join-password-box > input {
    padding: 12px 40px 12px 16px;
}

.join-input-box > input,
.join-password-box > input,
.join-name-box > input {
    width: 100%;
}

.join-input-box > div {
    margin-bottom: 20px;
}

.join-id-box > div > input,
.join-email-box > div > input,
.join-phone-box > div > input {
    width: 73%;
}

.join-id-box > div,
.join-email-box > div,
.join-phone-box > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.join-id-box > div > button,
.join-email-box > div > button,
.join-phone-box > div > button {
    height: 45px;
    width: 25%;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}


/* 회원가입 버튼 */


.join-btn-box > button {
    width: 100%;
    border-radius: 8px;
    background-color: #4255ff;
    color: white;
    height: 48px;
    font-size: 17px;
    border: none;
    margin: 20px 0;
    cursor: pointer;
}

.join-btn-box > button:hover {
    background-color: #4c16f9;
}

#check_true {
    background-color: #4cbc5c;
    color: white;
}

/* 비밀번호 보이기 아이콘 */
.join-password-box {
    position: relative;
}

.password-not-show-icon,
.password-show-icon {
    position: absolute;
    top: 38px;
    right: 13px;
}

.password-check-not-show-icon,
.password-check-show-icon {
    position: absolute;
    top: 95px;
    right: 13px;
}

