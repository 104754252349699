.chat-box-all {
    padding: 20px 0;
    background-color: #f6f7fb;
    display: flex;
    justify-content: space-between;
}

.chat-box {
    margin: 0 auto;
    width: 100%;
    max-width: 970px;
    border: 1px solid #ddd;
    min-height: 600px;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.chat-header h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.icon-btn {
    cursor: pointer;
    color: #555;
}

.text-box {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 300px;
}

.text-content h4 {
    margin: 0 0 2px;
    font-size: 16px;
    font-weight: 600;
}

.text-content p {
    font-size: 15px;
    color: #333;
    margin-bottom: 5px;
}

.wait-question {
    display: flex;
    flex-direction: column;

    text-align: center;
    color: #888;
    height: auto;
}

.wait {
    justify-content: center;
    align-items: center;
}

.input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 0;
    border-top: 1px solid #ddd;
}

.gpt-chat-box-all {
    width: 100%;
}


.gpt-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.gpt-input {
    flex-grow: 1;
    padding: 15px 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    resize: none; /* 크기 조절 비활성화 */
    height: 50px; /* 기본 높이 설정 */
    background-color: #f6f7fb; /* 일관성 있는 배경 색상 */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.gpt-input:focus {
    border-color: #8cdac7; /* 포커스 시 색상 변화 */
    box-shadow: 0 0 5px rgba(140, 218, 199, 0.5);
}

.send-btn {
    margin-left: 10px;
    color: #777;
    cursor: pointer;
    transition: color 0.2s ease, transform 0.2s ease;
}

.send-btn:hover {
    color: #8cdac7;
    transform: scale(1.1);
}

/* 사이드 바 */
.chat-side-bar {
    border: 1px solid #ddd;
    width: 0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.chat-box-all svg {
    cursor: pointer;
}

.chat-side-bar-header-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px 5px 8px;
}

.chat-side-bar-content-box {
    padding: 20px 10px 9px;
    overflow-y: auto;
    height: 554px;
}

.chat-side-bar-content-box {
    display: none;
}

.chat-side-bar-content-box div {
    transition: all 0.3s ease;
}

.chat-side-bar-content div:hover {
    background-color: #c0cef6;
    border-radius: 5px;
}

.chat-current-record {
    background-color: #c0cef6;
    border-radius: 5px;
}

.side-bar-content-not-active {
    display: block;
}

.chat-side-bar-content {
    margin-bottom: 20px;
}

.chat-side-bar-content > span {
    font-size: 14px;
    font-weight: 800;
    padding-left: 5px;
}

.chat-side-bar-content > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
}

.chat-side-bar-content > div > p {
    font-size: 15px;
    white-space: nowrap; /* 텍스트를 한 줄로 유지 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 */
    cursor: pointer;
    width: 80%;
}


.chat-side-bar-not-content {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 80%;
    flex-wrap: wrap;
}

.chat-side-bar-not-content > p {
    height: auto;
}


/* Responsive */
@media (max-width: 600px) {
    .chat-box {
        width: 370px;
    }

    .chat-side-bar-back-ground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(8, 11, 35, 0.5);
    }

    .chat-side-bar {
        position: fixed;
    }

    .chat-side-bar.side-bar-active {
        width: 70vw;
        top: 70px;
    }




}

@media (min-width: 600px) {
    .chat-box {
        width: 570px;
    }

    .chat-side-bar.side-bar-active {
        width: 210px;
    }

    .chat-side-bar-header-box {
        display: none;
    }

}

@media (min-width: 1000px) {
    .chat-box {
        width: 970px;
    }

    .chat-side-bar.side-bar-active {
        width: 250px;
    }

}
