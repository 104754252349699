.notice-box-all {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  background-color: white;
  width: 100%;
}

.notice-box {

}
.notice-box label {
  margin-right: 10px;
}
.noticePage-title-box {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
  width: 140px;

}

.noticePage-title-box > p {
  display: inline-block;
  border-bottom: 3px solid #2668f1;
}

.notice-title-box > p{
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.notice-title-box > input {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #d9d4d4;
  font-size: 15px;
  margin-bottom: 20px;
}
.notice-content-box {
  margin-bottom: 15px;
}


.notice-btn-box {
  margin-top: 30px;
}

.notice-btn-box > button {
  width: 90px;
  height: 35px;
  border-radius: 10px;
  border: none;
  background-color: #6CC4A1;
  color: white;
  font-size: 14px;
}


/* 600 이하 */
@media(max-width : 600px){
  .notice-box{
    margin-top: 40px;
    width: 95%;
  }
}

/* 600 이상 */
@media(min-width : 600px){
  .notice-box{
    margin-top: 40px;
    width: 90%;
  }

}
 /* 1000 이상 */
@media(min-width : 1000px){
  .notice-box{
    margin-top: 40px;
    width: 70%;
  }

}