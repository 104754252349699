
.login-all {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.login-info-box,
.login-info-all,
.login-info,
.login-input-box,
.login-btn-box{
    width: 100%;
}

.loginPage-box {
    width: 360px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    background-color: white;
}

.logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginPage-box .logo-box > img {
    position: absolute;
    width: 250px;
    height: 250px;
}

.logo-box > .logo-title-box {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginPage-box .logo-box > .logo-title-box > p {
    font-size: 21px;
    font-weight: 800;
}

/* login 정보 입력  */
.login-info {
    margin-top: 30px;
}

.login-info .login-input-box {
    display: flex;
    flex-direction: column;
}

.login-info .login-input-box > p {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.login-info .login-input-box > input {
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #d9d4d4;
    width: 100%;
    height: 45px;
    font-size: 16px;
    margin-bottom: 10px;
    outline-color : #2fb7f6;
}
.login-info .login-input-box > .login-password_input{
    padding: 12px 40px 12px 16px;
}


/* 아이디 저장, 아이디, 비밀번호 찾기 */
.login-middle-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.login-middle-box p,
.login-middle-box label {
    font-size: 15px;
}

.remember {
    display: flex;
    margin-left: 5px;
}

.remember > input {
    width: 14px;
    border-radius: 10px;
    margin-right: 10px;
    border-color: gray;
}

.id_pw_find {
    display: flex;
    font-weight: 700;
}

.id_pw_find > div > p:hover {
    font-weight: 800;
}



/* 로그인 버튼 */


.login-btn-box > button {
    width: 100%;
    border-radius: 8px;
    background-color: #16aef1b0;
    color: white;
    height: 48px;
    font-size: 17px;
    border: none;
    margin-bottom: 40px;
    cursor: pointer;
}

.login-btn-box > button:hover {
    background-color: #70d1fc;
}

.login-hr {
    border: 0.1px solid #d9d4d4;
    margin-bottom: 30px;
}


/* 소셜 로그인 */

.social-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}

.social-login > a {
    margin: 0 10px;
}

.social-login img {
    width: 40px;
    height: 40px;
}


/* 회원가입 버튼 */
.login-join-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-bottom: 76px;
}

.login-join-box > p{
    margin: 0 3px;
}
.login-join-btn {
    color: #0090d4;
    font-weight: bold;
    cursor: pointer;
}

/* 비밀번호 보이기/숨기기 */
.login-input-box {
    position: relative;
}

.login-password-not-show-icon,
.login-password-show-icon {
    position: absolute;
    top: 130px;
    right: 13px;
}

/* 500 이하 */
@media (max-width: 500px) {
    .loginPage-box .logo-box > img {
        top: 5px;
    }
    .logo-box {
        margin-top: 75px;
    }
}

/* 500 이상 */
@media (min-width: 500px) {
    .loginPage-box .logo-box > img {
        top: 40px;
    }
    .logo-box {
        margin-top: 110px;
    }
}

/* 1000 이상 */
@media (min-width: 1000px) {
    .loginPage-box .logo-box > img {
        top: 70px;
    }

    .logo-box {
        margin-top: 125px;
    }
}

