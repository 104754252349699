.user-notice-box-all {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}

.user-notice-title-box {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 87px;

}

.user-notice-title-box > p {
    display: inline-block;
    border-bottom: 3px solid #2668f1;
}
.user-notice-detail-box {
}

.user-notice-content-box {
    border-top: 1px solid #777;
    padding: 15px 0 10px 0;
    display: flex;
    flex-direction: column;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.user-notice-content-box:hover {
    background-color: rgb(167 197 255 / 22%);
}

.notice-content-high {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}
.notice-content-high > p {
    font-weight: 500;
}
.notice-content-row > p {
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 600;
}
.notice-content-high > svg {
    margin-left: 3px;
}

/* 제목 줄임 표시 */
.content-box-p-tag{
    white-space: nowrap;        /* 한 줄로 표시 */
    overflow: hidden;           /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis;    /* 넘치는 부분에 ... 표시 */
}

/* 600 까지 */
@media (max-width: 600px) {
    .user-notice-box {
        width: 95%;
        margin-top: 20px;
    }
    .content-box-p-tag {
        max-width : 200px
    }
}

/* 600 이상 */
@media (min-width: 600px) {
    .user-notice-box {
        width: 90%;
        margin-top: 30px;
    }
    .content-box-p-tag {
        max-width : 410px
    }
}

/* 1000 이상 */
@media (min-width: 1000px) {
    .user-notice-box {
        width: 70%;
        margin-top: 40px;
    }
    .content-box-p-tag {
        max-width : 590px
    }
}

