.inquiry-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    width: 100%;
}


.inquiry-title-box {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 87px;

}

.inquiry-title-box > p {
    border-bottom: 3px solid #2668f1;
    display: inline-block;
}

.inquiry-content-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #777;
    padding : 13px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

.inquiry-content-box {
    width: 100%;
}

/* 목록 없을 때 */
.inquiry-notData-box {
    height: 200px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 목록 있을 때 */
.inquiry-inData-box {

}

.inquiry-data-box {
    border-top: 1px solid #777;
    padding: 15px 0 10px 0;
    display: flex;
    flex-direction: column;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    cursor: pointer;
}

.inquiry-content-high-box {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.inquiry-content-row-box {
    display: flex;
    flex-direction: row;
}

.inquiry-comment{
    font-size: 14px;
    border-radius: 5px;
    padding-top: 2px;
    margin-right: 8px;
}

/* 답변완료, 미완료 태그 */
.inquiry-comment-y {
    background-color: #a0d995;
    color: white;
    font-weight: 700;
}
.inquiry-comment-n {
    background-color: #f75757bf;
    color: white;
    font-weight: 700;
}

.inquiry_title {
    font-weight: 500;
    white-space: nowrap;        /* 한 줄로 표시 */
    overflow: hidden;           /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis;    /* 넘치는 부분에 ... 표시 */
}

.inquiry_title > svg {
    width: 10px;
    height: 11px;
    margin-right: 5px;
}

.inquiry-content-row-box{
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 600;
}

.inquiry_regdate {
    margin-right: 5px;
}


/* 검색, 버튼 */
.inquiry-search-button-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.inquiry-search-box {
    display: flex;
    align-items: center;
}
.inquiry-search-box > input{
    width: 160px;
    border-radius: 7px;
    height: 30px;
    margin-right: 5px;
    padding-left: 10px;
    border: 2px solid #aeaeae;
    font-size: 17px;
}
.inquiry-search-box > input:focus{
    outline-color: #605f5f;
}

.inquiry-search-box > svg {
    color: #6f6f6f;
}
.inquiry-search-box > svg:hover{
    color: #605f5f;
}
.inquiry-button-box > button{
    width: 90px;
    height: 35px;
    border-radius: 8px;
    background-color: black;
    color: white;
    font-size: 17px;
    border: none;
}

.inquiry-button-box > button:hover {
    background-color: #1b1b1b;
}

/* 페이지 네이션 */
.inquiry-pageNation {
    width: 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

}

/* 반응형 */
/* 600 까지 */
@media(max-width : 600px){
    .inquiry-box{
        width: 95%;
        margin-top: 20px;
    }

}
/* 600 이상 */
@media(min-width : 600px){
    .inquiry-box{
        width: 90%;
        margin-top: 30px;
    }


}

/* 1000 이상 */
@media(min-width : 1000px){
    .inquiry-box{
        width: 70%;
        margin-top: 40px;
    }

}
