.main-favorite-note-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-favorite-note-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-favorite-note-title {
    font-weight: 700;
    line-height: 1.5;
    color: rgb(15, 19, 23);
    letter-spacing: -0.01em;
    word-break: keep-all;
}

.main-favorite-note-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(102, 115, 128);
    letter-spacing: -0.01em;
}
.main-favorite-note-more:hover {
    color: #0075ff;
}

/* 500 이하 */
@media (max-width: 500px) {
    .main-favorite-note-container {
        margin-top: 5px;
    }

    .main-favorite-note-title-box {
        width: 380px;
    }
    .main-favorite-note-title {
        font-size: 18px;
    }
    .main-favorite-note-more {
        font-size: 12px;
    }
    .main-favorite-note-more {
        padding-right: 15px;
    }
}

/* 500 이상 */
@media (min-width: 500px) {
    .main-favorite-note-container {
        margin-top: 10px;
    }

    .main-favorite-note-title-box {
        width: 480px;
    }

    .main-favorite-note-title {
        font-size: 22px;
    }
    .main-favorite-note-more {
        font-size: 14px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {
    .main-favorite-note-container {
        margin-top: 16px;
    }

    .main-favorite-note-title-box {
        width: 980px;
    }

    .main-favorite-note-title {
        font-size: 26px;
    }
    .main-favorite-note-more {
        font-size: 16px;
    }
}