/* 뒤로 가기 박스 */
.history-back-box {
    display: flex;
    align-items: center;
    color: #717680;
    margin-bottom: 14px;
    cursor: pointer;
}
.history-back-box:hover {
    color: #1b1b1b;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}
.history-back-box > p {
    font-size: 16px;
}


.notice-detail-content-box {
    padding: 20px 0;
    font-size: 18px;
}

/* 이전글, 다음글 박스 */
.notice-other-data-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #e2e5e9;
}

.notice-back-data-box,
.notice-next-data-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 14px 0;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.notice-other-title {
    color: #3b4859;
    white-space: nowrap;        /* 한 줄로 표시 */
    overflow: hidden;           /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis;    /* 넘치는 부분에 ... 표시 */
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
}

.notice-other-title:hover {
    color: black;
}

.notice-other-info {
    color: #b0b8c1;
    margin-right: 13px;
}

/* 목록으로 버튼 box */
.notice-list-btn-box {
    margin : 40px 0;
    cursor: pointer;
}

.notice-list-btn-box > button {
    width: 85px;
    height: 41px;
    border: 1px solid #d3d3d3;
    background-color: white;
    color: #212121;
    transition: all .12s cubic-bezier(.47, 0, .745, .715);
    font-size: 14px;
    border-radius: 3px;
}
.notice-list-btn-box > button:hover {
    background-color: #f5f5f5;
}

.notice-detail-date-box {
    color: #a8a7a7;
}

/* 600 까지 */
@media (max-width: 600px) {

    .notice-other-title {
        max-width : 200px
    }

    .notice-detail-title-box {
        font-size: 25px;
        font-weight: 700;
    }

    .notice-detail-date-box {
        font-size: 14px;
    }
}

/* 600 이상 */
@media (min-width: 600px) {

    .notice-other-title {
        max-width : 410px
    }

    .notice-detail-title-box {
        font-size: 33px;
        font-weight: 700;
    }
    .notice-detail-date-box {
        font-size: 15px;
    }

}

/* 1000 이상 */
@media (min-width: 1000px) {

    .notice-other-title {
        max-width : 590px
    }

    .notice-detail-title-box {
        font-size: 33px;
        font-weight: 700;
    }

    .notice-detail-date-box {
        font-size: 16px;
    }
}